/**
 * Utility providing localization functions.
 */

/**
 * Conditionally prepend locale to a relative path. Non-default locales
 * have the locale name prepended to the path.
 * @param object - a page context
 * @return function
 */
function localizer(ctx) {
  if (ctx && ctx.hasOwnProperty('locale') && ctx.hasOwnProperty('pathprefix')) {
    return function(to) { return (ctx.pathprefix ? `${ctx.pathprefix}${to}` : to); };
  }
  return function(to) { return to; };
};

/**
 * Determine the locale-specific path given a target language.
 * @param string - the target language or locale
 * @param string - the current path derived from page path (or location.pathname)
 * @param object - a page context
 * @return string or null if no locale change is indicated
 */
function changePath(targetLang, currentpath, ctx) {
  if (ctx.locale === targetLang) return null;
  let newpathPrefix = null;
  if (ctx.isVariationDefault) {
    newpathPrefix = (targetLang===process.env.GATSBY_DEFAULT_LANG ? '' : targetLang);
  } else {
    newpathPrefix = `${targetLang}-${ctx.variation}`;
  }
  const newpath = `/${newpathPrefix}`;
  return newpath;
}

export { localizer, changePath };
